import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Loader from "./loader";

const Container = styled.div`
  /* background: #999; */
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  /* padding: 10px; */
  overflow-x: scroll;
  overflow-y: auto;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
  position: relative;
  display: grid;
  table-layout: fixed;
  border-collapse: collapse;
  min-width: 100%;
  /* overflow-x: scroll; */
  grid-template-columns:
    minmax(50px, 1fr)
    minmax(200px, 1fr)
    minmax(200px, 1fr)
    minmax(300px, 2fr)
    minmax(150px, 1fr)
    minmax(400px, 3fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(150px, 1fr)
    minmax(400px, 3fr)
    minmax(150px, 1fr)
    minmax(180px, 1fr);
`;
const StyledThead = styled.thead`
  display: contents;
`;
const StyledTh = styled.th`
  background: white;
  position: sticky;
  top: 0;
  padding: 15px;
  /* word-wrap: break-word; */
  overflow: hidden;
  text-overflow: auto;
  /* white-space: nowrap; */
  display: flex;
  justify-content: center;
  align-items: center;

  background: green;
  text-align: center;
  font-weight: normal;
  font-size: 0.9rem;
  color: white;
  border-right: 1px solid #fff;

  &:last-child {
    border-right: none;
  }
`;
const StyledTbody = styled.tbody`
  display: contents;
`;

const StyledTr = styled.tr`
  /* border: 1px solid #333; */
  text-align: center;
  display: contents;

  &:nth-child(even) td {
    background: #f8f6ff;
  }
`;
const StyledTd = styled.td`
  border-right: 2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #999;
  padding: 15px;
  overflow: hidden;

  padding-top: 10px;
  padding-bottom: 10px;
  color: #333;
  :first-child {
    border-left: 2px solid #999;
  }
`;

const TeachersTable = () => {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getPods = async () => {
      setLoading(true);
      const response = await axios.get(
        "https://wp-api.proff86.ru/wp-json/wp/v2/teachers?per_page=100"
      );
      setLoading(false);
      console.log("@@@", response.data);
      setTeachers(response.data);
    };
    getPods();
  }, []);

  const sortedTeachers = teachers.sort(function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.title.rendered.toUpperCase();
    const bandB = b.title.rendered.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  });

  console.log(sortedTeachers);

  if (loading) {
    return <Loader />;
  }

  const renderedPods = sortedTeachers.map((pod, idx) => {
    return (
      <StyledTr key={pod.id}>
        <StyledTd>{idx + 1}</StyledTd>
        <StyledTd>{pod.title.rendered}</StyledTd>
        <StyledTd>{pod.role}</StyledTd>
        <StyledTd>{pod.disciplines}</StyledTd>
        <StyledTd>{pod.edulevel}</StyledTd>
        <StyledTd>{pod.qualification}</StyledTd>
        <StyledTd>{pod.grade}</StyledTd>
        <StyledTd>{pod.academic_title}</StyledTd>
        <StyledTd>{pod.direction}</StyledTd>
        <StyledTd>{pod.rising}</StyledTd>
        <StyledTd>{pod.work_experience}</StyledTd>
        <StyledTd>{pod.role_experience}</StyledTd>
      </StyledTr>
    );
  });

  return (
    <Container>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            <StyledTh>№ п.п.</StyledTh>
            <StyledTh>ФИО</StyledTh>
            <StyledTh>ДОЛЖНОСТЬ</StyledTh>
            <StyledTh>ПРЕПОДАВАЕМЫЕ ДИСЦИПЛИНЫ</StyledTh>
            <StyledTh>УРОВЕНЬ ОБРАЗОВАНИЯ</StyledTh>
            <StyledTh>КВАЛИФИКАЦИЯ</StyledTh>
            <StyledTh>УЧЕНАЯ СТЕПЕНЬ</StyledTh>
            <StyledTh>УЧЕНОЕ ЗВАНИЕ</StyledTh>
            <StyledTh>НАПРАВЛЕНИЕ ПОДГОТОВКИ</StyledTh>
            <StyledTh>ПОВЫШЕНИЕ КВАЛИФИКАЦИИ</StyledTh>
            <StyledTh>ОБЩИЙ СТАЖ РАБОТЫ</StyledTh>
            <StyledTh>СТАЖ РАБОТЫ ПО СПЕЦИАЛЬНОСТИ</StyledTh>
          </StyledTr>
        </StyledThead>
        <StyledTbody>{renderedPods}</StyledTbody>
      </StyledTable>
    </Container>
  );
};

export default TeachersTable;
