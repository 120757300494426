import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Loader from './loader';

const Container = styled.div`
    /* background: #999; */
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    /* padding: 10px; */
    overflow-x: scroll;
`;
// const Pre = styled.pre`
//   margin: 5px;
//   overflow-wrap: break-word;
// `;
const StyledTable = styled.table`
    display: grid;
    /* border-collapse: collapse; */
    min-width: 100%;
    grid-template-columns:
        minmax(50px, 0.1fr)
        minmax(250px, 2fr)
        minmax(250px, 2fr)
        minmax(165px, 165px)
        minmax(170px, 1fr);
`;
const StyledThead = styled.thead`
    display: contents;
`;
const StyledTh = styled.th`
    padding: 15px;
    /* word-wrap: break-word; */
    overflow: hidden;
    text-overflow: auto;
    /* white-space: nowrap; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background: green;
    text-align: center;
    font-weight: normal;
    font-size: 0.9rem;
    color: white;
    border-right: 1px solid #fff;

    &:last-child {
        border-right: none;
    }
`;
const StyledTbody = styled.tbody`
    display: contents;
`;

const StyledTr = styled.tr`
    /* border: 1px solid #333; */
    text-align: center;
    display: contents;

    &:nth-child(even) td {
        background: #f8f6ff;
    }
`;
const StyledTd = styled.td`
    border-right: 2px solid #999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #999;
    padding: 10px;
    overflow: hidden;

    padding-top: 10px;
    padding-bottom: 10px;
    color: #333;
    :first-child {
        border-left: 2px solid #999;
    }
`;

const ManagersTable = () => {
    const [managers, setManagers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const getPods = async () => {
            setLoading(true);
            const response = await axios.get(
                'https://wp-api.proff86.ru/wp-json/wp/v2/managers?per_page=100'
            );
            setLoading(false);
            // console.log("@@@", response.data);
            setManagers(response.data);
        };
        getPods();
    }, []);

    const sortedManagers = managers.sort((a, b) => a.id - b.id);

    if (loading) {
        return <Loader />;
    }

    return (
        <Container>
            <StyledTable>
                <StyledThead>
                    <StyledTr>
                        <StyledTh>№ п.п</StyledTh>
                        <StyledTh>ФИО</StyledTh>
                        <StyledTh>Должность</StyledTh>
                        <StyledTh>Контактный телефон</StyledTh>
                        <StyledTh>Электронная почта</StyledTh>
                    </StyledTr>
                </StyledThead>
                <StyledTbody className="">
                    {sortedManagers.map((manager, idx) => (
                        <StyledTr key={manager.id}>
                            <StyledTd>{idx + 1}</StyledTd>
                            <StyledTd>{manager.title.rendered}</StyledTd>
                            <StyledTd>{manager.role}</StyledTd>
                            <StyledTd>{manager.phone}</StyledTd>
                            <StyledTd>{manager.email}</StyledTd>
                        </StyledTr>
                    ))}
                </StyledTbody>
            </StyledTable>
            {/* <pre>{JSON.stringify(managers, null, 4)}</pre> */}
        </Container>
    );
};

export default ManagersTable;
