import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ManagersTable from '../components/managersTable';
import TeachersTable from '../components/teachersTable';

function Teachers() {
  return (
    <Layout>
      <SEO
        keywords={[
          `Руководство и педагогический состав`,
          `Профф`,
          `Пыть-Ях`,
          `Профессиональное образование`,
        ]}
        title='Преподаватели'
      />

      <section className='flex flex-col items-center '>
        <h1 className=' text-center text-xl uppercase tracking-wider text-green-800 font-bold mb-4'>
          Руководство и педагогический состав
        </h1>
        <ManagersTable />
        <h2 className=' text-center text-xl uppercase tracking-wider text-green-800 font-bold my-4'>
          СОСТАВ ПЕДАГОГИЧЕСКИХ РАБОТНИКОВ ОБРАЗОВАТЕЛЬНОЙ ОРГАНИЗАЦИИ
        </h2>
        <TeachersTable />
      </section>
    </Layout>
  );
}

export default Teachers;
